import { Vue, Component, Watch } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BFormTextarea,
  BFormFile,
  BLink
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import {
  SliderNamespace,
  SliderGetter,
  SliderAction
} from '@/store/slider/slider.module-types';
import { SliderViewModel, FileParameter } from '@/api/api';
import { dispatchSliderAction } from '@/store/slider/slider.dispatch';
import ApiClientFactory from '@/api/apiClientFactory';
import { quillEditor } from 'vue-quill-editor';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import { dispatchBreadcrumbAction } from '@/store/breadcrumb/breadcrumb.dispatch';
import { BreadcrumbAction } from '@/store/breadcrumb/breadcrumb.module-types';
import { BreadcrumbBuilder } from '@/utility/breadcrumb/breadcrumbFactory';
@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BCardText,
    BImg,
    BBadge,
    BFormTextarea,
    BFormFile,
    BLink,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    CustomLabel
  }
})
export default class SliderForm extends Vue {
  @SliderNamespace.Getter(SliderGetter.slider)
  slider!: SliderViewModel;

  input = this.getDefaultInputValue();

  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  URL = URL;

  $refs!: {
    formRules: InstanceType<typeof ValidationProvider>;
  };

  mounted() {
    this.setBreadcrumb();
    dispatchSliderAction(SliderAction.loadSlider);
  }

  setBreadcrumb() {
    dispatchBreadcrumbAction(
      BreadcrumbAction.setItems,
      new BreadcrumbBuilder().AddProductListBreadcrumb().Build()
    );
  }

  @Watch('slider')
  sliderUpdated(): void {
    if (this.slider) {
      this.input = {
        imageLinks: this.slider.imageLinks as string[],
        imageFiles: [] as any[]
      };
    } else {
      this.input = this.getDefaultInputValue();
    }
  }

  getDefaultInputValue() {
    return {
      imageLinks: [] as string[],
      imageFiles: [] as any[]
    };
  }

  add(): void {
    const client = new ApiClientFactory().sliderClient();
    const images: FileParameter[] = this.input.imageFiles.map((img) => {
      return {
        data: img,
        fileName: (img as any).name
      };
    });
    client.create(images).then(() => {
      // show toast
      this.$bvToast.toast('Thêm mới thành công', {
        title: 'Slider',
        toaster: 'b-toaster-bottom-right',
        variant: 'success'
      });
    });
  }

  edit() {
    const client = new ApiClientFactory().sliderClient();
    const imageFiles: FileParameter[] = this.input.imageFiles.map((img) => {
      return {
        data: img,
        fileName: (img as any).name
      };
    });
    client.edit(imageFiles, this.input.imageLinks).then(() => {
      // show toast
      this.$bvToast.toast('Chỉnh sửa thành công', {
        title: 'Slider',
        toaster: 'b-toaster-bottom-right',
        variant: 'success'
      });
    });
  }

  deleteImgLink(img: string) {
    const index = this.input.imageLinks.findIndex((x) => x === img);
    this.input.imageLinks.splice(index, 1);
  }

  deleteImgFile(img: any) {
    const index = this.input.imageFiles.findIndex(
      (x: any) => x.name === img.name
    );
    this.input.imageFiles.splice(index, 1);
  }

  imageChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.input.imageFiles.push(event.target.files[0]);
      console.log(this.input.imageFiles);
    }
  }

  submit(): void {
    this.$refs.formRules.validate().then((success) => {
      if (success) {
        if (this.slider) {
          this.edit();
        } else {
          this.add();
        }
      }
    });
  }
}
